import { CabButton, CabTextInput } from "@CabComponents";
import { Alert, Box, FormControl, FormLabel } from "@mui/material";
import { ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";
import PasswordInput from "../PasswordInput";
import StepHeader from "../StepHeader";
import { emailRegex } from "../../../constants";
import { validateEmail } from '../../../utils/inputUtils';
import { COGNITO_ERROR_CODE } from "../../../utils/cognitoErrorUtils";


export interface SignUpProps {
  onSubmit: (values: FormInput) => void;
  email: string;
  cognitoError?: { code: string; message: string };
}

export interface FormInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}


const SignUpStep = ({ onSubmit, email: defaultEmail, cognitoError }: SignUpProps): ReactElement => {
  const { control, formState: { errors, isSubmitting }, handleSubmit: handleFormSubmit, watch } = useForm<FormInput>({
    criteriaMode: 'all',
    defaultValues: { email: defaultEmail, firstName: '', lastName: '', password: '' },
  });

  const [email] = watch(['email']);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} width="100%">
      <StepHeader
        title="Create an account"
        text={<span>Let's set up an account for <b>{email}</b></span>}
      />

      <form onSubmit={handleFormSubmit(onSubmit)} style={{ width: 'inherit' }}>
        <Box display="flex" flexDirection="column" width="100%" flex={1} alignItems="center">
          <Box display="flex" flexDirection="column" gap={3} marginTop={4} width="100%"
            maxWidth="350px" flex={1} alignItems="center"
          >
            <FormControl sx={{ flex: 1, width: '100%' }}>
              <FormLabel>Work email</FormLabel>
              <Controller
                name="email"
                control={control}
                rules={{ required: true, pattern: emailRegex, validate: validateEmail }}
                render={({ field: { ref, ...field } }) => (
                  <CabTextInput {...field} disabled={isSubmitting} />
                )}
              />
              {errors.email && (
                <Alert severity="error" sx={{ marginTop: 1 }}>
                  {errors.email.message
                || (errors?.email?.type === 'required' ? 'Email is required'
                  : errors.email?.type === 'pattern' ? 'Please enter a valid email address'
                    : null)}
                </Alert>
              )}
            </FormControl>


            <FormControl sx={{ flex: 1, width: '100%' }}>
              <FormLabel>First name</FormLabel>
              <Controller name="firstName" control={control} rules={{ required: true }} 
                render={({ field: { ref, ...field } }) => (
                  <CabTextInput {...field} autoComplete="given-name" />
                )}
              />
              {errors.firstName && (
                <Alert severity="error" sx={{ marginTop: 1 }}>
                  {errors?.firstName?.type === 'required' ? 'First name is required' : null}
                </Alert>
              )}
            </FormControl>

            <FormControl sx={{ flex: 1, width: '100%' }}>
              <FormLabel>Last name</FormLabel>
              <Controller name="lastName" control={control} rules={{ required: true }} 
                render={({ field: { ref, ...field } }) => (
                  <CabTextInput {...field} autoComplete="family-name" />
                )}
              />
              {errors.lastName && (
                <Alert severity="error" sx={{ marginTop: 1 }}>
                  {errors?.lastName?.type === 'required' ? 'Last name is required' : null}
                </Alert>
              )}
            </FormControl>

            <FormControl sx={{ flex: 1, width: '100%' }}>
              <FormLabel>Password</FormLabel>
              <PasswordInput
                name="password"
                control={control}
                error={errors.password}
                isNew
                showSecurityError={cognitoError?.code === COGNITO_ERROR_CODE.INVALID_PASSWORD_EXCEPTION}
              />
            </FormControl>

            {cognitoError && cognitoError.code !== COGNITO_ERROR_CODE.INVALID_PASSWORD_EXCEPTION && (
              <Alert severity="error" sx={{ marginTop: 1 }}>
                You can't use this email address to create an account
              </Alert>
            )}

            <CabButton
              size="large"
              type="submit"
              sx={{ width: '100%' }}
              disabled={isSubmitting}
            >
              Create account
            </CabButton>

          </Box>
        </Box>

      </form>
    </Box>
  );
};

export default SignUpStep;
