import { ReactElement, useCallback, useEffect, useState } from "react";
import queryString from 'query-string';
import SignUpStep from "./SignUp";
import { useLocation, useParams } from "react-router-dom";
import { Preferences } from "@capacitor/preferences";
import { getEmailDomain } from "../../../utils/authUtils";
import { skipToken } from "@reduxjs/toolkit/query";
import { core } from "../../../store/cabinetApi/core";


interface UserInfo {
  email?: string;
}

export interface SignUpStepContainerProps {
  onUpdateUserInfo: ({ email }: { email: string }) => void;
  onFinish: (p: { userInfo: UserInfo }) => void;
  email?: string;
}

const SignUpStepContainer = ({
  onUpdateUserInfo, onFinish, email
}: SignUpStepContainerProps): ReactElement => {
  const location = useLocation();
  const { orgCode: orgCodeParam } = useParams<{orgCode?: string}>();

  const [orgCode, setOrgCode] = useState<string | undefined>(orgCodeParam);
  const [orgExists, setOrgExist] = useState<boolean>(false);

  const { data: organization, isSuccess } = core.useCoreOrgPublicOrgRetrieveQuery(orgCode || skipToken);
  const [fetchPublicOrgByDomain] = core.useLazyCoreOrgPublicOrgByDomainRetrieveQuery();

  const organizationExists = isSuccess || orgExists;

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    Object.keys(parsed)
      .filter(key => ['planTier', 'planInterval', 'promoCode', 'referralCode'].includes(key))
      .forEach(key => {
        const value = parsed[key];
        if (value && typeof value === 'string') {
          Preferences.set({ key, value: value.toUpperCase() });
        }
      });
  }, [location.search]);

  const isSSO = useCallback(async (checkEmail: string) => {
    const domain = getEmailDomain(checkEmail);
    const res = await fetchPublicOrgByDomain(domain);
    if (res.isSuccess && res.data.org_code) {
      setOrgExist(true);
      setOrgCode(res.data.org_code);
      return true;
    }
    return false;
  }, [fetchPublicOrgByDomain]);

  const handleSubmit = useCallback(async (values: { email: string }) => {
    const ssoRequired = await isSSO(values.email);
    if (!ssoRequired) {
      onUpdateUserInfo(values);
      onFinish({ userInfo: { email: values.email }});
    }
  }, [isSSO, onFinish, onUpdateUserInfo]);

  return (
    <SignUpStep onSubmit={handleSubmit} orgCode={orgCode} orgExists={organizationExists}
      organization={organization} autoSubmitEmail={orgCode ? undefined : email} email={email} />
  );
};

export default SignUpStepContainer;
