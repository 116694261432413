import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Preferences } from '@capacitor/preferences';
import PlanSelection, { FormInput } from "./PlanSelection";
import { useDispatch } from "react-redux";
import { ThunkDispatchType, actions } from "../../../store";
import { BILLING_INTERVAL, PAGE_URL, OLD_TIER, TIER, NEW_TIER, LARGE_TEAMS, EVENT_TYPE } from "../../../constants";
import { getCheckoutSessionId, redirectToCheckout, trackPurchase } from "../../../utils/paymentUtils";
import { useCabinetText } from "../../../CabinetContext";
import { trackEventWithExtra } from "../../../utils/appAnalyticsUtils";
import { useNavigate } from "react-router";


const handleTrackPurchase = (
  value: number, currency: string, tier: string, quantity: number, confirmation: string
) => {
  const purchaseData = {
    transaction_id: confirmation,
    value: value * quantity,
    currency,
    items: [{
      id: 0,
      name: tier,
      brand: "Cabinet",
      category: "Premium Subscription",
      quantity,
      price: value,
    }],
  };
  trackPurchase(purchaseData);
  trackEventWithExtra({ eventName: EVENT_TYPE.PURCHASE, extra: purchaseData });
};


export interface PlanSelectionContainerProps {
  onFinish: () => void;
  options?: { confirmation?: string; qty?: number; sessionId?: string; val?: number; cur?: string; tier?: string };
}

const PlanSelectionContainer = ({ onFinish, options }: PlanSelectionContainerProps): ReactElement => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();
  const [dialogError, setDialogError] = useState<string | undefined>();
  const [defaultSelection, setDefaultSelection] = useState<{ planTier?: TIER, billedAnnually?: boolean } | undefined>();
  const logout = useCallback(async () => {
    await dispatch(actions.auth.logout());
    navigate(PAGE_URL.LOGIN);
  }, [dispatch, navigate]);

  const [individualDetails, starterDetails, growthDetails, premierDetails] = useCabinetText([
    'onboard-individual-features',
    'onboard-starter-features',
    'onboard-growth-features',
    'onboard-premier-features'
  ]);


  const planOptions = useMemo(() => {
    return [
      {
        id: NEW_TIER.INDIVIDUAL,
        name: 'Individual',
        costMonthly: 39,
        costAnnually: 31,
        caption: '1 seat',
        details: individualDetails,
        buttonText: 'Choose this plan',
        planDescription: 'For a single person managing one or multiple calendars',
      },
      {
        id: NEW_TIER.STARTER,
        name: 'Starter',
        costMonthly: 49,
        costAnnually: 43,
        caption: '2-9 seats',
        details: starterDetails,
        buttonText: 'Choose this plan',
        planDescription: 'For a small team of EAs and Admins who need collaboration',
      },
      {
        id: NEW_TIER.GROWTH,
        name: 'Growth',
        costMonthly: 99,
        costAnnually: 99,
        caption: '10 seat minimum',
        details: growthDetails,
        buttonText: 'Learn More',
        planDescription: 'For a large team of EAs and Admins who need collaboration ',
      },
      {
        id: NEW_TIER.PREMIER,
        name: 'Premier',
        costMonthly: 229,
        costAnnually: 229,
        caption: '50 seat minimum',
        details: premierDetails,
        buttonText: 'Learn More',
        planDescription: 'For a large teams of admins who need collaboration and custom builds ',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualDetails.join(','), starterDetails.join(','), growthDetails.join(','), premierDetails.join(',')]);

  const handlePaymentStartFailed = () => {
    setDialogError(`Uh oh - Something went wrong! Try again \
                    or send an SOS to our team at help@joincabinet.com.`);
  };

  const checkDefaultSelection = useCallback(async () => {
    const { value: planTier } = await Preferences.get({ key: 'planTier' });
    const { value: planInterval } = await Preferences.get({ key: 'planInterval' });
    const defaults: { planTier?: TIER, billedAnnually?: boolean } = {};
    if (planTier) {
      defaults.planTier = planTier as TIER;
    }
    if (planInterval) {
      defaults.billedAnnually = planInterval === BILLING_INTERVAL.YEAR;
    }
    setDefaultSelection(defaults);
  }, []);

  useEffect(() => {
    checkDefaultSelection();
  }, [checkDefaultSelection]);

  // finish this step if checkout was successful
  useEffect(() => {
    if (options) {
      // TODO: verify this is working
      const { confirmation, qty, sessionId, val, cur, tier } = options;
      if (confirmation && qty && sessionId && val != null && cur && tier) {
        onFinish();
        handleTrackPurchase(val, cur, tier, qty, confirmation);
      }
    }
  }, [options, onFinish]);

  const handleFinish = async (values: FormInput) => {
    const { billedAnnually, planTier } = values;
    const billingInterval = billedAnnually ? BILLING_INTERVAL.YEAR : BILLING_INTERVAL.MONTH;

    trackEventWithExtra({ 
      eventName: EVENT_TYPE.ONBOARD_CONTINUE_WITH_PLAN, 
      extra: {tier: planTier} 
    });

    if (planTier === OLD_TIER.BASIC) {
      onFinish();
    } else if ([OLD_TIER.ESSENTIALS, OLD_TIER.PRO, NEW_TIER.INDIVIDUAL, NEW_TIER.STARTER].includes(planTier)) {
      // Coupon codes are available in the Stripe Checkout UI for first-time purchases so we don't handle it here
      // Right now we don't handle more than 1 seat purchases during onboarding
      const quantity = 1;
      const cancelUrl = `${window.location.origin}${PAGE_URL.SIGNUP}?stripeCancel=true`;
      try {
        const session = await getCheckoutSessionId(
          '', planTier, billingInterval, quantity, window.location.href, PAGE_URL.SIGNUP, cancelUrl
        );
        if (session?.status === 200) {
          if (!session.data.active_sub_id && session.data.session_id) {
            redirectToCheckout(session.data.session_id);
          } else if (session.data.active_sub_id) {
            await dispatch(actions.organization.changeSubscription(planTier, billingInterval, quantity, null));
            onFinish();
          } else {
            handlePaymentStartFailed();
          }
        }
        
      } catch (error) {
        handlePaymentStartFailed();
      }
    } else {
      window.open(LARGE_TEAMS, '_blank');
    }
  };

  return (
    <PlanSelection
      defaultSelection={defaultSelection}
      planOptions={planOptions}
      dialogError={dialogError}
      onDismissError={() => setDialogError(undefined)}
      onFinish={handleFinish}
      onLogout={logout}
    />
  );
};

export default PlanSelectionContainer;
