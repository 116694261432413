import { Preferences } from "@capacitor/preferences";
import { ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { actions, ThunkDispatchType } from "../../../store";
import SignUpNamePassStep, { FormInput } from "./SignUpNamePassword";


interface UserInfo {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface SignUpNamePassStepContainerProps {
  onUpdateUserInfo: ({ email, firstName, lastName, password }: FormInput) => void;
  onFinish: (p: { userInfo: UserInfo }) => void;
  email: string;
}

const SignUpNamePassStepContainer = ({
  onUpdateUserInfo, onFinish, email
}: SignUpNamePassStepContainerProps): ReactElement => {
  const [cognitoError, setCognitoError] = useState<{ code: string; message: string } | undefined>();
  const dispatch = useDispatch<ThunkDispatchType>();

  const handleSubmit = async (values: FormInput) => {
    const { value: referralCode } = await Preferences.get({ key: 'referralCode' });

    onUpdateUserInfo(values);
    const registrationError = await dispatch(actions.auth.register(
      email, values.password, values.firstName, values.lastName, referralCode || undefined
    ));

    if (!registrationError) {
      onFinish({ userInfo: values });
    } else {
      setCognitoError({
        code: registrationError.errorType || '',
        message: registrationError.message || 'There was an error during registration',
      });
    }
  };

  return (
    <SignUpNamePassStep onSubmit={handleSubmit} email={email} cognitoError={cognitoError} />
  );
};

export default SignUpNamePassStepContainer;
