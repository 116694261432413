import { CabButton, CabDropdown, CabModal, CabTextInput } from "@CabComponents";
import { Alert, Box, FormControl, FormLabel, Typography } from "@mui/material";
import { ReactElement, useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import colors from "../../../colors";
import { Organization } from "../../../store";

export interface CompanyInfoProps {
  organization: Organization;
  onFinish: (values: Organization) => void;
  buttonText?: string;
}

export interface FormInput {
  companyName: string;
  numAssistants: string;
}

type OrgFormInput = Omit<Organization, "num_eas"> & {
  num_eas: '0' | '1' | '2-5' | '6-10' | '11-30' | '31-100' | '101+' | 'Unknown'
};

const CompanyInfo = ({ organization, onFinish, buttonText }: CompanyInfoProps): ReactElement => {
  const defaultValues = useMemo(() => ({
    name: organization.name || "",
    num_eas: organization?.num_eas || "0"
  }), [organization]);
  
  const {
    control, formState, handleSubmit
  } = useForm<OrgFormInput>({
    defaultValues
  });

  const onValid: SubmitHandler<Organization> = (data) => {
    onFinish(data);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} width="100%">
      <CabModal
        open
        title="Personalize your experience"
        onClose={handleSubmit(onValid)}
        closeOnBackdropClick={false}
        actionButtons={(
          <CabButton
            size="large"
            sx={{ backgroundColor: colors.black900 }}
            disabled={formState.isSubmitting}
            onClick={handleSubmit(onValid)}
          >
            {buttonText || 'Save'}
          </CabButton>
        )}
      >
        <Typography variant="body1" color={colors.black800}>Tell us a bit more about your company</Typography>
        <Box display="flex" flexDirection="column" gap={3} marginTop={4} width="100%"
          flex={1} alignItems="center"
        >
          <FormControl sx={{ flex: 1, width: '100%' }}>
            <FormLabel>Company Name</FormLabel>
            <Controller name="name" control={control} rules={{ required: true }} 
              render={({ field: { ref, ...field }, fieldState: { error } }) => {
                return <>
                  <CabTextInput {...field} />
                  {error && (
                    <Alert severity="error" sx={{ marginTop: 1 }}>
                      {error?.type === 'required' ? 'Company name is required' : null}
                    </Alert>
                  )}
                </>;
              }}
            />
          </FormControl>

          <FormControl sx={{ flex: 1, width: '100%' }}>
            <FormLabel>How many assistants work at your company?</FormLabel>
            <Controller name="num_eas" control={control} rules={{ required: true }} 
              render={({ field: { ref, ...field }, fieldState: {error} }) => {
                return <><CabDropdown {...field} options={[
                  { value: '0', label: 'None' },
                  { value: '1', label: 'Just Me' },
                  { value: '2-5', label: '2-5' },
                  { value: '6-10', label: '6-10' },
                  { value: '11-30', label: '11-30' },
                  { value: '31-100', label: '31-100' },
                  { value: '101+', label: '101+' },
                ]} />
                {error && (
                  <Alert severity="error" sx={{ marginTop: 1 }}>
                    {error?.type === 'required' ? 'Number of assistants is required' : null}
                  </Alert>
                )}
                </>;
              }}
            />
          </FormControl>
        </Box>
      </CabModal>
    </Box>
  );
};

export default CompanyInfo;
