import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Box, FormControl, FormLabel, Typography } from "@mui/material";
import { IoArrowForward } from 'react-icons/io5';
import { CabAutocomplete, CabButton, CabIcon } from "@CabComponents";
import { AllOAuthGrantDetails, Calendar } from "../../../store";
import StepHeader from "../StepHeader";
import { uniqBy } from "lodash-es";
import CabSpinner from "@CabComponents/CabSpinner";
import { checkForCalendarGrants } from "../../../utils/authUtils";


export interface IndividualCalendarStepProps {
  calendars: Calendar[];
  selectedCalendarIds?: number[];
  leaderExists: boolean;
  oauth_grant_details: AllOAuthGrantDetails;
  onFinish: (selectedCalendarsIds: number[]) => Promise<void>;
  buttonText?: string;
}


const IndividualCalendarStep = ({
  calendars, selectedCalendarIds, oauth_grant_details, leaderExists, onFinish, buttonText
}: IndividualCalendarStepProps): ReactElement => {

  const [selectedCalendars, setSelectedCalendars] = useState<number[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const connectedCalendar = checkForCalendarGrants(oauth_grant_details);

  const noCalendarsConnected = useMemo(() => 
    leaderExists && !connectedCalendar
  , [leaderExists, connectedCalendar]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    await onFinish(selectedCalendars);
    setIsSubmitting(false);
  }, [onFinish, selectedCalendars]);

  useEffect(() => {
    if (!leaderExists && !connectedCalendar && !isSubmitting) {
      handleSubmit();
    }
  }, [handleSubmit, isSubmitting, leaderExists, connectedCalendar]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      {(!leaderExists && !connectedCalendar) || isSubmitting ? (
        <Box marginTop={5}>
          <CabSpinner scale={4} />
        </Box>
      ) : (
        <>
          <StepHeader
            title='Select Your Main Calendars'
            text={noCalendarsConnected
              ? 'You have not connected any calendar accounts.'
              : 'This will be your default calendar(s) when offering times.'
            }
          />
          {connectedCalendar &&
            <Box maxWidth={'600px'} width={'100%'} marginTop={3}>
              <FormControl fullWidth>
                <FormLabel>My Calendars</FormLabel>
                <CabAutocomplete<number, never>
                  placeholder="Select a calendar"
                  onChange={(v) => Array.isArray(v) && setSelectedCalendars(v)}
                  defaultValue={selectedCalendarIds}
                  multiple
                  sx={{ width: 'auto' }}
                  options={uniqBy(
                    [...calendars],
                    cal => cal.id
                  ).map((calendar) => ({
                    value: calendar.id,
                    label: calendar.additionalCalendarEmail ? calendar.additionalCalendarEmail.name : calendar.summary,
                    color: calendar.backgroundColor
                  }))}
                />
              </FormControl>
            </Box>
          }
          <Box maxWidth={'600px'} width={'100%'} marginTop={3} textAlign='center'>
            {noCalendarsConnected && (
              <>
                <Typography> 
                  Go back to the previous step and connect your Google or Outlook account.
                </Typography>
              </>
            )}
          </Box>
          <Box display="flex" maxWidth={'500px'} justifyContent="center" marginTop={6}>
            <CabButton
              size="large"
              onClick={handleSubmit}
              endIcon={<CabIcon size="small" Icon={IoArrowForward} />}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CabSpinner scale={1} sx={{ width: 32 }} /> : (buttonText || 'Next')}
            </CabButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default IndividualCalendarStep;
