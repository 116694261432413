import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_URL } from "../../constants";
import { RootState, ThunkDispatchType, actions } from "../../store";
import SignUp from "./SignUp";
import { useNavigate } from "react-router";


const SignUpContainer = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();

  // user who has finished onboarding shouldn't see signup page
  useEffect(() => {
    if (user?.profile.app_onboarding_completed) {
      navigate(PAGE_URL.DASHBOARD);
    }
  }, [user?.profile.app_onboarding_completed, navigate]);

  return <SignUp
    onFinishOnboarding={() => {
      dispatch(actions.auth.finishedOnboarding());
      navigate(PAGE_URL.SCHEDULE);
    }}
  />;
};

export default SignUpContainer;
