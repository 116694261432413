import { ReactElement } from "react";
import CompanyInfo from "./CompanyInfo";
import { useDispatch, useSelector } from "react-redux";
import { actions, Organization, RootState, ThunkDispatchType } from "../../../store";

export interface CompanyInfoContainerProps {
  onFinish: () => void;
  buttonText?: string;
}

const CompanyInfoContainer = ({ onFinish, buttonText }: CompanyInfoContainerProps): ReactElement => {
  const organization = useSelector((state: RootState) => state.organization);
  const dispatch = useDispatch<ThunkDispatchType>();
  
  const onFinishWithAction = async (values: Organization) => {
    await dispatch(actions.organization.updateOrganization({
      id: organization.id,
      name: values.name,
      num_eas: values.num_eas
    }));
    onFinish();
  };

  return <CompanyInfo
    organization={organization}
    onFinish={onFinishWithAction}
    buttonText={buttonText}
  />;
};

export default CompanyInfoContainer;
