import { uniq } from "lodash-es";
import { OLD_TIER, NEW_TIER, ONBOARD_STEP, USER_ROLE } from "../constants";
import { OrganizationState, User } from "../store";


export type OnboardingUser = Pick<User,
'id'|'oauth_grant_details'|'active_license'|'permissions'|'email'|'first_name'|'last_name'|'features'>;

export type OnboardingOrg = Pick<OrganizationState, 'num_eas'|'subscriptionDetails'|'active'|'name'|
'onboarding_call_scheduled'|'is_on_contract'>;

export const defaultOnboardingSteps: readonly ONBOARD_STEP[] = [
  ONBOARD_STEP.SIGNUP_EMAIL,
  ONBOARD_STEP.SIGNUP_NAME_PASS,
  ONBOARD_STEP.VERIFY_EMAIL,
  ONBOARD_STEP.USER_TYPE,
  ONBOARD_STEP.CHOOSE_PLAN,
  ONBOARD_STEP.BOOK_ENTERPRISE_MEETING,
  ONBOARD_STEP.WELCOME_CALENDAR_CONNECT,
  ONBOARD_STEP.EXEC_CALENDARS,
  ONBOARD_STEP.ADD_CALENDARS,
  ONBOARD_STEP.CONFERENCE_CONNECT,
];


export interface OnboardingState {
  availableSteps: ONBOARD_STEP[];
  stepIndex: number;
  stepProps: Record<string, string | number>;
  seenSteps: Set<ONBOARD_STEP>; // once a step is reached, it is "seen"
  completedSteps: ONBOARD_STEP[]; // once a step is passed, it is "completed"
  onboardingIsComplete: boolean;
  userEmail?: string;
  userPassword?: string;
  options?: { showUserType?: boolean };
  backStepIndex: number | null;
}

// a valid step is a step that the user should be able to see. Some, but not all, steps are no
// longer considered valid once they have been completed (i.e. they shouldn't be seen again, even with back button)
const stepIsValidForUser = (
  step: ONBOARD_STEP, state: OnboardingState, user: OnboardingUser | null, organization: OnboardingOrg | null,
  hasAddedLeaders: boolean, options: { showUserType?: boolean } = {},
) => {
  const userExists = !!user;
  const hasCompletedStep = state.completedSteps.includes(step);
  const showUserType = options.showUserType !== false;
  
  switch (step) {
    case ONBOARD_STEP.SIGNUP_EMAIL: {
      if (userExists) {
        return false;
      }
      break;
    }
    case ONBOARD_STEP.SIGNUP_NAME_PASS: {
      if (userExists) {
        return false;
      }
      break;
    }
    case ONBOARD_STEP.VERIFY_EMAIL: {
      if (userExists) {
        return false;
      }
      break;
    }
    case ONBOARD_STEP.USER_TYPE: {
      if (!userExists || !showUserType
        || user.active_license.onsignup_assistant
        || (user.active_license.onsignup_leaders?.length || 0) > 0
        || !user.active_license.created_by
        || user.active_license.created_by !== user.id) {
        return false;
      }
      break;
    }

    case ONBOARD_STEP.CHOOSE_PLAN: {
      if (
        !userExists
        || organization?.active
        || organization?.is_on_contract
        || user.active_license.tier !== OLD_TIER.BASIC
        // Users must have the permissions to manage billing and there must be a customer account
        || (!user?.permissions.STRIPE_SUBSCRIPTION_MGMT && !!organization?.subscriptionDetails?.has_customer_account)
      ) { 
        return false;
      }
      break;
    }

    case ONBOARD_STEP.BOOK_ENTERPRISE_MEETING: {
      if (!userExists || !showUserType || hasCompletedStep || !user?.permissions.CONTENT_EDITOR
        || organization?.onboarding_call_scheduled || (user.active_license.user_role === USER_ROLE.INDIVIDUAL)
        || (user.active_license.tier === NEW_TIER.INDIVIDUAL && (['0', '1'].includes(organization?.num_eas || '0')))) {
        return false;
      }
      break;
    }
    
    case ONBOARD_STEP.WELCOME_CALENDAR_CONNECT: {
      if (!userExists) {
        return false;
      }
      break;
    }
    case ONBOARD_STEP.EXEC_CALENDARS: {
      if (!userExists || user.active_license.user_role === USER_ROLE.INDIVIDUAL || 
        !!user?.features?.DISABLE_USER_LEADER_CONTROL) {
        return false;
      }
      break;
    }
    case ONBOARD_STEP.ADD_CALENDARS: {
      if (!userExists || user.active_license.user_role !== USER_ROLE.INDIVIDUAL) {
        return false;
      }
      break;
    }
    case ONBOARD_STEP.CONFERENCE_CONNECT: {
      if (!userExists) {
        return false;
      }
      break;
    }
    case ONBOARD_STEP.ONE_LAST_THING: {
      if ((organization?.name !== null && organization?.name !== '') || 
        user?.active_license.user_role === USER_ROLE.INDIVIDUAL) {
        return false;
      }
      break;
    }

    default:
      return true;
  }

  return true;
};

// given the current onboarding state, return the step a user can go back to (if any)
const getPreviousStepIndex = (
  currentState: OnboardingState, user: OnboardingUser | null, organization: OnboardingOrg | null,
  hasAddedLeaders: boolean, options: { showUserType?: boolean } = {},
): number | null => {
  // no previous step if we are on the first step
  if (currentState.stepIndex === 0) return null;

  if (currentState.completedSteps.length > 0) {
    const currentStep = currentState.availableSteps.at(currentState.stepIndex);
    if (!currentStep) return null;

    const currentStepCompletedIndex = currentState.completedSteps.indexOf(currentStep);
    let previousStep: ONBOARD_STEP | undefined;
    if (currentStepCompletedIndex > 0) {
      previousStep = currentState.completedSteps.at(currentStepCompletedIndex - 1);
    } else {
      previousStep = currentState.completedSteps.at(-1);
    }

    if (previousStep && stepIsValidForUser(previousStep, currentState, user, organization, hasAddedLeaders, options)) {
      const previousIndex = currentState.availableSteps.lastIndexOf(previousStep);
      return previousIndex > -1 ? previousIndex : null;
    }
  }

  return null;
};

export const getPreviousOnboardingState = (
  previousIndex: number, currentState: OnboardingState, user: OnboardingUser | null, organization: OnboardingOrg | null,
  hasAddedLeaders: boolean, options: { showUserType?: boolean } = {},
): OnboardingState | null => {
  const previousStep = currentState.availableSteps.at(previousIndex);

  if (!previousStep || !currentState.completedSteps.includes(previousStep)) return null;

  const previousStepIsValid = stepIsValidForUser(
    previousStep, currentState, user, organization, hasAddedLeaders, options,
  );
  if (previousStep && previousStepIsValid) {
    const previousState = {
      ...currentState,
      stepIndex: previousIndex,
      stepProps: {},
      backStepIndex: null,
    };

    return {
      ...previousState,
      backStepIndex: getPreviousStepIndex(previousState, user, organization, hasAddedLeaders, options),
    };
  }

  return null;
};


// should be called only once when onboarding is mounted. Deals with redirects and localstorage state
export const initializeOnboardingState = (
  persistedState: {
    showUserType?: boolean;
  },
  urlParams: {
    sessionId?: string; val?: number; cur?: string; tier?: string; stripeCancel?: boolean; email?: string;
    confirmation?: string; newUser?: string; qty?: number; code?: string; forceBasic?: boolean; flag?: string
  },
  urlPathname: string,
  navigationState: {
    onboardStep?: ONBOARD_STEP;
    onboardEmail?: string;
    onboardPassword?: string;
  },
  userIsLoading: boolean,
  availableSteps: ONBOARD_STEP[],
  user: OnboardingUser | null,
  organization: OnboardingOrg | null,
  hasAddedLeaders: boolean,
  completedSteps: ONBOARD_STEP[] = [],
  seenSteps: Set<ONBOARD_STEP> = new Set(),
): OnboardingState => {
  // Onboarding should start at the first valid step that has not been completed
  // OR at a specified step (via navigation step, or interpreted through urlParams) that is valid

  if (userIsLoading) {
    throw Error('Onboarding cannot be initialized until user data has loaded!');
  }

  const steps = urlParams.forceBasic
    ? availableSteps.filter(s => s !== ONBOARD_STEP.CHOOSE_PLAN)
    : availableSteps;

  const state: OnboardingState = {
    availableSteps: steps,
    stepIndex: -1,
    stepProps: {},
    seenSteps,
    completedSteps,
    onboardingIsComplete: false,
    options: {
      showUserType: persistedState.showUserType,
    },
    backStepIndex: null,
  };

  let lastCompletedStepIndex: number | undefined;

  if (user && organization?.onboarding_call_scheduled
    && !state.completedSteps.includes(ONBOARD_STEP.BOOK_ENTERPRISE_MEETING)
  ) {
    state.completedSteps.push(ONBOARD_STEP.BOOK_ENTERPRISE_MEETING);
  }

  // === Redirect Handling ===
  // if returning from Stripe successfully
  if (urlParams.confirmation && urlParams.qty && urlParams.sessionId && urlParams.val != null
    && urlParams.cur && urlParams.tier
  ) {
    state.stepIndex = state.availableSteps.indexOf(ONBOARD_STEP.CHOOSE_PLAN);
    state.stepProps.confirmation = urlParams.confirmation;
    state.stepProps.qty = urlParams.qty;
    state.stepProps.sessionId = urlParams.sessionId;
    state.stepProps.val = urlParams.val;
    state.stepProps.cur = urlParams.cur;
    state.stepProps.tier = urlParams.tier;
  // if returning from Stripe after cancel/back
  } else if (urlParams.stripeCancel) {
    state.stepIndex = state.availableSteps.indexOf(ONBOARD_STEP.CHOOSE_PLAN);
  // if returning from microsoft
  } else if (urlPathname.includes("microsoft") && urlParams.code) {
    state.stepIndex = state.availableSteps.indexOf(ONBOARD_STEP.WELCOME_CALENDAR_CONNECT);
    state.stepProps.microsoftCode = urlParams.code;
  // if returning from zoom
  } else if (urlPathname.includes("zoom") && urlParams.code) {
    state.stepIndex = state.availableSteps.indexOf(ONBOARD_STEP.CONFERENCE_CONNECT);
    state.stepProps.zoomCode = urlParams.code;
  // === INTERNAL ROUTING ===
  } else if (navigationState.onboardStep != null) {
    const onboardStep = navigationState.onboardStep;

    state.stepIndex = state.availableSteps.indexOf(onboardStep);
  } else {
    const lastCompletedStep = completedSteps?.at(-1);
    lastCompletedStepIndex = lastCompletedStep != null ? steps.indexOf(lastCompletedStep) : -1;
    state.stepIndex = lastCompletedStepIndex;
  }

  state.userEmail = navigationState.onboardEmail || urlParams.email || undefined;
  state.userPassword = navigationState.onboardPassword || undefined;

  if (state.stepIndex < 0 || lastCompletedStepIndex != null) {
    return getNextOnboardingState(state, user, organization, hasAddedLeaders, state.options);
  }

  state.seenSteps.add(state.availableSteps[state.stepIndex]);

  return state;
};

// should be called to get next onboarding state and step
export const getNextOnboardingState = (
  currentState: OnboardingState, user: OnboardingUser | null, organization: OnboardingOrg | null,
  hasAddedLeaders: boolean, options: { showUserType?: boolean } = {}, lastCompletedStep?: ONBOARD_STEP
): OnboardingState => {
  const currentStepIndex = currentState.stepIndex;

  // onboarding completed
  if (currentStepIndex === currentState.availableSteps.length - 1) {
    return {
      ...currentState,
      completedSteps: uniq([
        ...currentState.completedSteps,
        ...(lastCompletedStep != null ? [lastCompletedStep] : []),
      ]),
      onboardingIsComplete: true,
      backStepIndex: null,
    };
  } else {
    const nextStepIndex = currentStepIndex > -1 ? currentStepIndex + 1 : 0;
    const nextStep = currentState.availableSteps[nextStepIndex];

    // this should never happen
    if (!nextStep) {
      throw Error('Something went wrong');
    }

    if (stepIsValidForUser(nextStep, currentState, user, organization, hasAddedLeaders, options)) {
      const nextState = {
        ...currentState,
        stepIndex: nextStepIndex,
        completedSteps: uniq([
          ...currentState.completedSteps,
          ...(lastCompletedStep != null ? [lastCompletedStep] : []),
        ]),
        seenSteps: new Set([...currentState.seenSteps, nextStep]),
      };
      nextState.backStepIndex = getPreviousStepIndex(nextState, user, organization, hasAddedLeaders, options);
      return nextState;
    } else {
      return getNextOnboardingState(
        { ...currentState, stepIndex: nextStepIndex },
        user, organization, hasAddedLeaders, options, lastCompletedStep
      );
    }
  }
};
