import { isEqual } from "lodash-es";
import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PROVIDER } from "../../../constants";
import { actions, Grant, RootState, ThunkDispatchType } from "../../../store";
import { logoutOAuth } from "../../../store/auth/actions";
import { getGrants } from "../../../utils/authUtils";
import VirtualConferenceConnect, { PresetLocationFormInput, zoomRedirectURI } from "./VirtualConferenceConnect";

export interface VirtualConferenceConnectContainerProps {
  onFinish: () => void;
  buttonText?: string;
  options?: { zoomCode?: string };
}

const VirtualConferenceConnectContainer = ({
  onFinish, buttonText, options
}: VirtualConferenceConnectContainerProps): ReactElement => {
  const auth = useSelector((state: RootState) => state.auth);
  const presetLocations = useSelector((state: RootState) => state.schedule.presetLocations);
  const dispatch = useDispatch<ThunkDispatchType>();

  const grants = getGrants(auth);
  const zoomGrant = grants.find(grant => grant.provider === PROVIDER.ZOOM.name && grant.signedIn);
  const hasGoogleConnected = grants.some(grant => grant.provider === PROVIDER.GOOGLE.name && grant.signedIn);
  const hasMSConnected = grants.some(grant => grant.provider === PROVIDER.MICROSOFT.name && grant.signedIn);

  const handleFinish = async () => {
    onFinish();
  };

  const handleSignOut = (grant: Grant) => {
    dispatch(logoutOAuth(grant));
  };

  const handlePresetLocationSubmit = async (data: PresetLocationFormInput) => {
    const deleteIds = Object.values(presetLocations)
      .filter(loc => !data.locations.find(l => l.id === loc.id))
      .map(loc => loc.id);

    const dirtyData = data.locations
      .filter(loc => !deleteIds.includes(loc.id))
      .filter(loc => !loc.id || !isEqual(presetLocations[loc.id], loc));

    dirtyData.forEach(loc => {
      if (loc.id && loc.name && loc.location) {
        dispatch(actions.schedule.updatePresetLocation(loc.id, loc.name, loc.location));
      } else {
        if (loc.location && loc.name) {
          dispatch(actions.schedule.createPresetLocation(loc.name, loc.location));
        }
      }
    });

    deleteIds.forEach(id => {
      dispatch(actions.schedule.deletePresetLocation(id));
    });
  };

  useEffect(() => {
    dispatch(actions.schedule.fetchPresetLocations());
  }, [dispatch]);

  //Need to fetch user zoom setting whenever the grant is updated
  useEffect(() => {
    dispatch(actions.schedule.fetchZoomSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(grants)]);

  // handle return from redirect
  useEffect(() => {
    if (options) {
      const { zoomCode: code } = options;
      if (code) {
        dispatch(actions.auth.saveOAuthGrant(code, PROVIDER.ZOOM, zoomRedirectURI));
      }
    }
  }, [options, dispatch]);

  return <VirtualConferenceConnect
    onFinish={handleFinish}
    showZoom={!!auth.user?.features.ZOOM}
    zoomGrant={zoomGrant}
    hasGoogleConnected={hasGoogleConnected}
    hasMSConnected={hasMSConnected}
    buttonText={buttonText}
    onZoomSignOut={zoomGrant && (() => handleSignOut(zoomGrant))}
    presetLocations={presetLocations}
    onPresetLocationSubmit={handlePresetLocationSubmit}
  />;
};

export default VirtualConferenceConnectContainer;
