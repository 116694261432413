import { ReactElement } from "react";
import BookEnterpriseMeeting from "./BookEnterpriseMeeting";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ThunkDispatchType, actions } from "../../../store";


export interface UserTypeContainerProps {
  onFinish: () => void;
  email: string;
  firstName: string;
  lastName: string;
  buttonText?: string;
}


const BookEnterpriseMeetingContainer = ({ onFinish, email, firstName, lastName
}: UserTypeContainerProps): ReactElement => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const organization = useSelector((state: RootState) => state.organization);
  const externalMeetingId = import.meta.env.VITE_ENTERPRISE_MEETING_ID;

  const setOrganizationOnboardingScheduled = () => {
    dispatch(actions.organization.updateOrganization({
      onboarding_call_scheduled: true,
      id: organization.id
    }));
  };

  return (
    <BookEnterpriseMeeting
      email={email}
      firstName={firstName}
      lastName={lastName}
      onFinish={onFinish}
      externalMeetingId={externalMeetingId || ''}
      setOrgMeetingScheduled={setOrganizationOnboardingScheduled}
    />
  );
};

export default BookEnterpriseMeetingContainer;
