import { Box } from "@mui/material";
import Onboarding from "../../components/Onboarding";
import { ONBOARD_STEP } from "../../constants";


export interface SignUpProps {
  onFinishOnboarding: () => void;
  onReachFinalStep?: () => void;
}


const SignUp = ({ onFinishOnboarding, onReachFinalStep }: SignUpProps) => (
  <Box display="flex" flexDirection="column" alignItems="center" width="100%" height="100%">
    <Onboarding
      steps={[
        ONBOARD_STEP.SIGNUP_EMAIL,
        ONBOARD_STEP.SIGNUP_NAME_PASS,
        ONBOARD_STEP.VERIFY_EMAIL,
        ONBOARD_STEP.USER_TYPE,
        ONBOARD_STEP.CHOOSE_PLAN,
        ONBOARD_STEP.BOOK_ENTERPRISE_MEETING,
        ONBOARD_STEP.WELCOME_CALENDAR_CONNECT,
        ONBOARD_STEP.EXEC_CALENDARS,
        ONBOARD_STEP.ADD_CALENDARS,
        ONBOARD_STEP.CONFERENCE_CONNECT,
        ONBOARD_STEP.ONE_LAST_THING,
      ]}
      onFinish={onFinishOnboarding}
      onReachFinalStep={onReachFinalStep}
      finalStepButtonText="Launch Cabinet"
    />
  </Box>
);

export default SignUp;
