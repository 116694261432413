import { CabButton } from "@CabComponents/CabButton";
import { Box, Grid } from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { IoArrowBack } from 'react-icons/io5';
import { CabIcon } from "@CabComponents/CabIcon";


interface StepWrapperProps {
  children: ReactNode;
  onBack: () => void;
  hideBackButton?: boolean;
}

const StepWrapper = ({ children, onBack, hideBackButton }: StepWrapperProps): ReactElement => (
  <Box paddingLeft={4} paddingRight={4} paddingTop={10} width="100%" paddingBottom={4}>
    <Grid container>
      <Grid item md={1} xs={12} width={{ xs: '100%' }}>
        <Box display="flex" justifyContent={{ xs: "center", md: 'unset' }} marginBottom={3}>
          {!hideBackButton && (
            <CabButton
              onClick={onBack}
              icon={<CabIcon Icon={IoArrowBack} />}
              buttonType="tertiary"
              color="accent"
              size="small"
            >
              Back
            </CabButton>
          )}
        </Box>
      </Grid>

      <Grid item md={10} xs={12}>
        {children}
      </Grid>

      <Grid item md={1} xs={12}>
      </Grid>
    </Grid>
  </Box>
);

export default StepWrapper;
