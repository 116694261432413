import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabModal } from "@CabComponents/CabModal";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Box, Typography, FormControl, FormLabel, Alert } from "@mui/material";
import chroma from "chroma-js";
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import colors from "../../../colors";
import { emailRegex, EVENT_TYPE } from "../../../constants";
import { Calendar } from "../../../store";
import { trackEvent } from "../../../utils/appAnalyticsUtils";
import CabSpinner from "@CabComponents/CabSpinner";

export type SearchMissingCalendarsProps = {
  handleSetAdditionalCalendars: (value: { [eml: string]: Calendar[] }) => void
  additionalCalendars: { [eml: string]: Calendar[] }
  onGetEmailCalendars: (email: string) => Promise<Calendar[]>
};

const SearchMissingCalendars = ({
  handleSetAdditionalCalendars, onGetEmailCalendars,
  additionalCalendars
}: SearchMissingCalendarsProps) => {

  const [showEmailModal, setShowEmailModal] = useState(false);

  const {
    control: emailControl, formState: { errors: emailErrors, isSubmitting }, setError, handleSubmit: handleEmailSubmit,
    getValues, reset
  } = useForm<{ email: string }>({
    defaultValues: { email: '' },
  });

  const handleGetEmailCalendars = async (email: string) => {
    const calendars = await onGetEmailCalendars(email);
    if (calendars.length === 0) {
      setError('email', { message: 'No calendars found. Please try another email address.' });
    } else {
      trackEvent(EVENT_TYPE.ONBOARD_MS_DELEGATE);
      handleSetAdditionalCalendars({ ...additionalCalendars, [email]: calendars });
      setShowEmailModal(false);
      reset();
    }
  };

  return <Box 
    padding={2} 
    sx={{ 
      backgroundColor: chroma(colors.purplePrimary).alpha(0.3).hex(),
      width: "100%"
    }}
    justifyContent="center"
  >
    {Object.keys(additionalCalendars).length > 0 ? (
      <div>
        {Object.keys(additionalCalendars).map(emailAddress => (
          <Box key={emailAddress} display="flex" flexDirection="row" alignItems="center" gap={1}>
            <CabIcon Icon={IoCheckmarkCircleOutline} />
            <Typography fontWeight={700}>{emailAddress}</Typography>
          </Box>
        ))}
        <div color={colors.black800}>
          Calendars from these email addresses will now appear above
        </div>
        <CabButton
          onClick={() => setShowEmailModal(true)}
          buttonType="text"
          sx={{ textDecoration: 'underline', padding: 0, marginTop: 1 }}
        >
          Add another email address
        </CabButton>
      </div>
    ) : (
      <div>
        <Typography fontWeight={700}>Missing Calendars?</Typography>
        <Typography color={colors.black800} marginTop={1}>
          Try adding your executive's email address to access missing calendars
        </Typography>
        <CabButton
          onClick={() => setShowEmailModal(true)}
          buttonType="text"
          sx={{ textDecoration: 'underline', padding: 0, marginTop: 1 }}
        >
          Add executive email address
        </CabButton>
      </div>
    )}

    <CabModal
      open={showEmailModal}
      onClose={() => setShowEmailModal(false)}
      title="Fetch calendars from email"
      closeOnBackdropClick={!isSubmitting}
      closeIcon={!isSubmitting}
      actionButtons={<>
        <CabButton
          onClick={() => setShowEmailModal(false)}
          sx={{ backgroundColor: colors.black200, color: colors.black900 }}
          disabled={isSubmitting}
        >Cancel</CabButton>
        <CabButton
          onClick={() => handleEmailSubmit(({ email }) => handleGetEmailCalendars(email))()}
          sx={{ color: colors.black100, backgroundColor: colors.black900 }}
          disabled={isSubmitting}
        >Fetch Calendars</CabButton>
      </>}
    >
      {isSubmitting ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box margin={2}>
            <CabSpinner scale={1} />
          </Box>
          <Typography color={colors.black600}>Fetching calendars from</Typography>
          <Typography color={colors.black600} fontWeight={700}>{getValues('email')}</Typography>
        </Box>
      ) : (
        <FormControl fullWidth>
          <FormLabel>Executive's email</FormLabel>
          <Controller
            name="email"
            control={emailControl}
            rules={{ required: true, pattern: emailRegex }}
            render={({ field: { ref, ...field } }) => (
              <CabTextInput {...field} />
            )}
          />
          {emailErrors.email && (
            <Alert severity="error" sx={{ marginTop: 1 }}>
              {emailErrors.email.message
                || (emailErrors?.email?.type === 'required' ? 'Email is required'
                  : emailErrors.email?.type === 'pattern' ? 'Please enter a valid email address'
                    : null)}
            </Alert>
          )}
        </FormControl>
      )}
    </CabModal>
  </Box>;
};

export default SearchMissingCalendars;