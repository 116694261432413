import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Box, Typography } from '@mui/material';
import { Preferences } from '@capacitor/preferences';
import StepWrapper from './StepWrapper';
import WelcomeCalendarConnectStep from './WelcomeCalendarConnect';
import ExecutiveCalendarStep from './ExecutiveCalendarStep';
import colors from '../../colors';
import SignUp from './SignUp';
import SignUpNamePassword from './SignUpNamePassword';
import VerifyEmailStep from './VerifyEmailStep';
import PlanSelection from './PlanSelection';
import CompanyInfo from './CompanyInfo';
import { actions, Organization, RootState, ThunkDispatchType } from '../../store';
import { trackEvent } from '../../utils/appAnalyticsUtils';
import { EVENT_TYPE, ONBOARD_STEP, PAGE_URL, USER_ROLE } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { CabButton } from '@CabComponents/CabButton';
import CabSpinner from '@CabComponents/CabSpinner';
import UserTypeContainer from './UserType';
import IndividualCalendarStep from './IndividualCalendarStep';
import VirtualConferenceConnect from './VirtualConferenceConnect';
import BookEnterpriseMeetingContainer from './BookEnterpriseMeeting';
import {
  getNextOnboardingState, getPreviousOnboardingState, initializeOnboardingState,
} from '../../utils/onboardingUtils';
import { useSearchParams } from "react-router-dom";
// import { setIsOnboardingInitialized } from '../../store/auth/actions';
import { removeLocalStorageOnboarding } from '../../utils/storageUtils';
import { isLargeFormatDevice } from '../../utils/screenSizeUtils';

export interface OnboardingContainerProps {
  steps: ONBOARD_STEP[];
  onFinish: () => void;
  onReachFinalStep?: () => void;
  finalStepButtonText: string;
  forceInitialization?: boolean;
}

interface UserInfo {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
}

const stepsToEventNames: { [key: number]: typeof EVENT_TYPE[keyof typeof EVENT_TYPE] } = {
  [ONBOARD_STEP.SIGNUP_EMAIL]: EVENT_TYPE.ONBOARD_SIGNUP,
  [ONBOARD_STEP.SIGNUP_NAME_PASS]: EVENT_TYPE.ONBOARD_NAME_PASS,
  [ONBOARD_STEP.VERIFY_EMAIL]: EVENT_TYPE.ONBOARD_VERIFY_EMAIL,
  [ONBOARD_STEP.USER_TYPE]: EVENT_TYPE.ONBOARD_USER_TYPE,
  [ONBOARD_STEP.CHOOSE_PLAN]: EVENT_TYPE.ONBOARD_CHOOSE_PLAN,
  [ONBOARD_STEP.BOOK_ENTERPRISE_MEETING]: EVENT_TYPE.ONBOARD_BOOK_ENTERPRISE_MEETING,
  [ONBOARD_STEP.WELCOME_CALENDAR_CONNECT]: EVENT_TYPE.ONBOARD_CONNECT_CALENDAR,
  [ONBOARD_STEP.EXEC_CALENDARS]: EVENT_TYPE.ONBOARD_EXEC_CALENDAR,
  [ONBOARD_STEP.ADD_CALENDARS]: EVENT_TYPE.ONBOARD_ADD_CALENDARS,
  [ONBOARD_STEP.CONFERENCE_CONNECT]: EVENT_TYPE.ONBOARD_CONFERENCE_CONNECT,
  [ONBOARD_STEP.ONE_LAST_THING]: EVENT_TYPE.ONBOARD_ONE_LAST_THING
};

const OnboardingContainer = ({
  steps, onFinish, onReachFinalStep, finalStepButtonText, forceInitialization
}: OnboardingContainerProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  
  const user = useSelector((state: RootState) => state.auth.user);
  const isLoading = useSelector((state: RootState) => state.auth.isLoading);
  const organization = useSelector((state: RootState) => state.organization);
  const leaders = useSelector((state: RootState) => state.leaders);
  // const isOnboardingInitialized = useSelector((root: RootState) => root.auth.isOnboardingInitialized);
  const [isOnboardingInitialized, setIsOnboardingInitialized] = useState(false);
  const dispatch = useDispatch<ThunkDispatchType>();

  const [availableSteps, setAvailableSteps] = useState<ONBOARD_STEP[]>([]);
  const [stepIndex, setStepIndex] = useState<number>(-1);
  const [stepProps, setStepProps] = useState<Record<string, string | number>>({});
  const [userInfo, setUserInfo] = useState<UserInfo>({});
  const [showUserType, setShowUserType] = useState(true);
  const [seenSteps, setSeenSteps] = useState<Set<ONBOARD_STEP>>(new Set());
  const [completedSteps, setCompletedSteps] = useState<ONBOARD_STEP[]>([]);
  const [onboardingIsComplete, setOnboardingIsComplete] = useState(false);
  const [backStepIndex, setBackStepIndex] = useState<number | null>(null);

  const step = availableSteps[stepIndex] || ONBOARD_STEP.LOADING;

  const handleSetSeenSteps = useCallback((newSeenSteps: ONBOARD_STEP[]) => {
    newSeenSteps.forEach(seenStep => Preferences.set({ key: `onboardingStep.${seenStep}`, value: 'true' }));
  }, []);

  const handleSetCompletedSteps = useCallback((newCompletedSteps: ONBOARD_STEP[]) => {
    newCompletedSteps.forEach(completedStep => Preferences.set({ 
      key: `completedStep.${completedStep}`, value: 'true' 
    }));
  }, []);

  const handleClearSeenAndCompletedSteps = async () => {
    await removeLocalStorageOnboarding();
    setSeenSteps(new Set());
  };

  const isLastStep = stepIndex > -1 && stepIndex === (availableSteps.length - 1);

  const handleLogout = () => dispatch(actions.auth.logout());

  const handleNext = useCallback((
    { userInfo: curUserInfo, companyInfo }: { userInfo?: UserInfo; companyInfo?: Organization } = {}
  ) => {
    const {
      availableSteps: newAvailableSteps,
      stepIndex: newStepIndex, stepProps: newStepProps, seenSteps: newSeenSteps, completedSteps: newCompletedSteps,
      onboardingIsComplete: newOnboardingIsComplete, userEmail, userPassword, backStepIndex: newBackStepIndex,
    } = getNextOnboardingState(
      {
        availableSteps, stepIndex, stepProps, seenSteps, completedSteps, onboardingIsComplete,
        userEmail: curUserInfo?.email, userPassword: curUserInfo?.password, options: { showUserType },
        backStepIndex
      },
      user || null,
      organization,
      leaders.leaders.length > 1,
      { showUserType },
      stepIndex > -1 ? availableSteps.at(stepIndex) : undefined,
    );

    if (newOnboardingIsComplete) {
      trackEvent(EVENT_TYPE.ONBOARD_COMPLETE);
      // clear persisted steps in case another onboarding happens in this browser or session
      handleClearSeenAndCompletedSteps();
      onFinish();
    } else {
      const nextStep = availableSteps[newStepIndex];
      trackEvent(stepsToEventNames[nextStep]);

      setAvailableSteps(newAvailableSteps);
      setStepIndex(newStepIndex);
      setBackStepIndex(newBackStepIndex);
      setStepProps(newStepProps);
      setSeenSteps(newSeenSteps);
      setCompletedSteps(newCompletedSteps);
      setUserInfo({ email: userEmail, password: userPassword });
      setOnboardingIsComplete(newOnboardingIsComplete);

      handleSetSeenSteps([...newSeenSteps]);
      handleSetCompletedSteps(newCompletedSteps);
    }
  }, [availableSteps, stepIndex, stepProps, seenSteps, completedSteps, onboardingIsComplete,
    showUserType, user, organization, leaders.leaders.length, onFinish, backStepIndex,
    handleSetSeenSteps, handleSetCompletedSteps
  ]);

  const handleBack = () => {
    if (backStepIndex != null) {
      const prevState = getPreviousOnboardingState(
        backStepIndex,
        {
          availableSteps, stepIndex, stepProps, seenSteps, completedSteps, onboardingIsComplete,
          userEmail: userInfo.email, userPassword: userInfo.password, options: { showUserType },
          backStepIndex,
        },
        user || null,
        organization,
        leaders.leaders.length > 1,
        { showUserType },
      );

      if (prevState) {
        setAvailableSteps(prevState.availableSteps);
        setStepIndex(prevState.stepIndex);
        setBackStepIndex(prevState.backStepIndex);
        setStepProps(prevState.stepProps);
        setSeenSteps(prevState.seenSteps);
        setCompletedSteps(prevState.completedSteps);
        setUserInfo({ email: prevState.userEmail, password: prevState.userPassword });
        setOnboardingIsComplete(prevState.onboardingIsComplete);
      } else {
        setBackStepIndex(null);
      }
    }
  };

  const handleMobileBack = () => {
    handleLogout();
    navigate(PAGE_URL.LOGIN);
  };

  const handleUpdateUserInfo = useCallback((newUserInfo: UserInfo) => {
    setUserInfo({ ...userInfo, ...newUserInfo });
  }, [userInfo, setUserInfo]);

  // onboarding initialization
  const onboardingInitialization = useCallback(async () => {
    // for some reason the navigation state is only immediately available on `history.location` rather than just
    // `location`, so in order to get it right away we must use that.
    const navState = location.state;

    if (!isLoading && !isOnboardingInitialized) {
      // must set this as soon as we get into this condition
      // dispatch(setIsOnboardingInitialized(true));
      setIsOnboardingInitialized(true);
      const urlParams = queryString.parse(location.search, { parseNumbers: true, parseBooleans: true }) as {
        sessionId?: string; val?: number; cur?: string; tier?: string; stripeCancel?: boolean; email?: string;
        confirmation?: string; newUser?: string; qty?: number; code?: string; forceBasic?: boolean; flag?: string
      };

      if (urlParams.flag === 'alaska') {
        await Preferences.set({ key: 'showUserType', value: 'true' });
      }
      const { value: showUserTypeValue } = await Preferences.get({ key: 'showUserType' });

      const showUserTypeStep = showUserTypeValue == null || showUserTypeValue === 'true';
      setShowUserType(showUserTypeStep);

      // get seen steps from local storage
      const savedSeenSteps = new Set<ONBOARD_STEP>();
      const keys = await Preferences.keys();
      keys.keys.filter(key => key.startsWith('onboardingStep.')).forEach(key => {
        const val = key.substring('onboardingStep.'.length);
        savedSeenSteps.add(Number(val));
      });

      // get completed steps from local storage
      const completedKeys = await Preferences.keys();
      const savedCompletedSteps = completedKeys.keys
        .filter(key => key.startsWith('completedStep.'))
        .map(key => {
          const val = key.substring('completedStep.'.length);
          return Number(val);
        });

      const completed: ONBOARD_STEP[] = [];
      // order completed steps based on available steps
      steps.forEach(avStep => {
        if (savedCompletedSteps.includes(avStep)) {
          completed.push(avStep);
        }
      });

      const initialOnboardingState = initializeOnboardingState(
        { showUserType: showUserTypeStep },
        urlParams,
        location.pathname,
        navState || {},
        isLoading,
        steps,
        user || null,
        organization,
        leaders.leaders.length > 1,
        completed,
        savedSeenSteps,
      );

      setAvailableSteps(initialOnboardingState.availableSteps);
      setStepProps(initialOnboardingState.stepProps);
      setSeenSteps(initialOnboardingState.seenSteps);
      setCompletedSteps(initialOnboardingState.completedSteps);
      setOnboardingIsComplete(initialOnboardingState.onboardingIsComplete);
      setUserInfo({ email: initialOnboardingState.userEmail, password: initialOnboardingState.userPassword });
      setBackStepIndex(initialOnboardingState.backStepIndex);
      // intentionally set this last because it has more rendering side effects (this won't be necessary when
      // state update batching is enabled via React 18)
      setStepIndex(initialOnboardingState.stepIndex);

      // clear url parameters and navigation state
      setSearchParams("");

      navigate(location.pathname, {
        replace: true,
        state: { onboardStep: undefined, onboardEmail: undefined, onboardPassword: undefined },
      });
    }
  // NOTE: the dep array wants to see location passed in but on every navigation
  // location.key changes which causes an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, leaders.leaders.length, JSON.stringify(location.state?.onboardStep), JSON.stringify(steps),
    location.pathname, location.search, isOnboardingInitialized, organization, showUserType, user, navigate,
    setSearchParams,
    // seenSteps, completedSteps, availableSteps
  ]);

  useEffect(() => {
    if (!user?.profile.app_onboarding_completed || forceInitialization) {
      onboardingInitialization();
    }
  }, [onboardingInitialization, user?.profile.app_onboarding_completed, forceInitialization]);

  // handle onboarding finish
  useEffect(() => {
    if (step == null) {
      // clear "seen steps" just in case another onboarding happens in this browser or session
      handleClearSeenAndCompletedSteps();
      onFinish();
    }
  }, [step, onFinish]);

  // handle last step effects
  useEffect(() => {
    // if we have reached the last step
    if (onReachFinalStep && step && isLastStep) {
      onReachFinalStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, isLastStep]);

  const { stepComponent } = useMemo(() => {
    let newStepComponent = null;
    const buttonText = isLastStep ? finalStepButtonText : undefined;
    switch (step) {
      case ONBOARD_STEP.LOADING:
        newStepComponent = <CabSpinner />;
        break;

      case ONBOARD_STEP.SIGNUP_EMAIL:
        newStepComponent = <SignUp
          onUpdateUserInfo={handleUpdateUserInfo}
          onFinish={handleNext}
          email={userInfo.email || ''}
        />;
        break;

      case ONBOARD_STEP.SIGNUP_NAME_PASS:
        newStepComponent = (
          <SignUpNamePassword
            onUpdateUserInfo={handleUpdateUserInfo}
            onFinish={handleNext}
            email={userInfo.email || ''}
          />
        );
        break;

      case ONBOARD_STEP.VERIFY_EMAIL:
        newStepComponent = <VerifyEmailStep
          onFinish={handleNext}
          email={userInfo.email || ''}
          password={userInfo.password}
        />;
        break;

      case ONBOARD_STEP.USER_TYPE:
        newStepComponent = <UserTypeContainer onFinish={handleNext} />;
        break;

      case ONBOARD_STEP.CHOOSE_PLAN:
        newStepComponent = <PlanSelection
          options={stepProps}
          onFinish={handleNext}
        />;
        break;

      case ONBOARD_STEP.BOOK_ENTERPRISE_MEETING:
        newStepComponent = <BookEnterpriseMeetingContainer 
          onFinish={handleNext}
          email={user?.email || ''}
          firstName={user?.first_name || ''}
          lastName={user?.last_name || ''}
          buttonText={buttonText}
        />;
        break;

      case ONBOARD_STEP.WELCOME_CALENDAR_CONNECT:
        newStepComponent = <WelcomeCalendarConnectStep
          onFinish={handleNext}
          loading={false}
          buttonText={buttonText}
          options={stepProps}
        />;
        break;

      case ONBOARD_STEP.EXEC_CALENDARS:
        // This step is only seen by Assistants
        newStepComponent = <ExecutiveCalendarStep
          onFinish={handleNext}
          buttonText={buttonText}
        />;
        break;

      case ONBOARD_STEP.ADD_CALENDARS:
        // This step is only seen by Individuals
        newStepComponent = <IndividualCalendarStep
          onFinish={handleNext}
          buttonText={buttonText}
        />;
        break;

      case ONBOARD_STEP.CONFERENCE_CONNECT:
        newStepComponent = <VirtualConferenceConnect
          onFinish={handleNext}
          buttonText={organization.name || user?.active_license.user_role === USER_ROLE.INDIVIDUAL ? 
            'Launch Cabinet' : buttonText}
          options={stepProps}
        />;
        break;

      case ONBOARD_STEP.ONE_LAST_THING:
        newStepComponent = <CompanyInfo
          onFinish={handleNext}
          buttonText={buttonText}
        />;
        break;

      case null:
        newStepComponent = null;
        break;
    
      default:
        newStepComponent = <SignUp onUpdateUserInfo={handleUpdateUserInfo} onFinish={handleNext} />;
        break;
    }

    return { stepComponent: newStepComponent };
  }, [isLastStep, finalStepButtonText, step, handleUpdateUserInfo, handleNext, userInfo.email, userInfo.password, 
    stepProps, user?.email, user?.first_name, user?.last_name, user?.active_license.user_role, organization.name]);

  return (
    <Box sx={{ backgroundColor: colors.greyBackdrop, height: '100%', width: '100%' }} overflow='auto'>
      {isLargeFormatDevice() ? 
        <StepWrapper onBack={handleBack} hideBackButton={backStepIndex == null}>
          {stepComponent}
        </StepWrapper>
        : 
        <Box display='flex' gap={2} flexDirection='column' padding={2} justifyContent='center' height='100%'>
          <Typography textAlign='center' variant='h1'>Account Setup</Typography>
          <Typography variant='body1'>
            For the best onboarding experience, please log in at app.joincabinet.com from a desktop web browser 
            to set up your Cabinet account for the first time.
          </Typography>
          <CabButton
            onClick={handleMobileBack}
          >
            Go Back
          </CabButton>
        </Box>
      }
    </Box>
  );
};

export default OnboardingContainer;
