import { ReactElement, useEffect } from "react";
import WelcomeCalendarConnect from "./WelcomeCalendarConnect";
import { useDispatch, useSelector } from "react-redux";
import { actions, Grant, RootState, ThunkDispatchType } from "../../../store";
import { getGoogleBookingScope, getGrants } from "../../../utils/authUtils";
import { EVENT_TYPE, PAGE_URL, PROVIDER } from "../../../constants";
import { trackEvent } from "../../../utils/appAnalyticsUtils";
import { useCabinetText } from "../../../CabinetContext";


export interface WelcomeCalendarConnectStepContainerProps {
  onFinish: () => void;
  loading: boolean;
  buttonText?: string;
  options?: { microsoftCode?: string };
}

const msRedirectURI = `${window.location.origin}${PAGE_URL.SIGNUP}/oauth/microsoft`;

const WelcomeCalendarConnectStepContainer = ({
  onFinish, loading, buttonText, options
}: WelcomeCalendarConnectStepContainerProps): ReactElement => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<ThunkDispatchType>();

  const [description] = useCabinetText(['onboard-connect-calendar']);

  const handleGoogleSignIn = () => {
    dispatch(actions.auth.signInWithGoogle(getGoogleBookingScope()));
  };

  const handleMSSignIn = async () => {
    const url = (await dispatch(
      actions.auth.getMsLoginUrl('onboarding', msRedirectURI)
    )) as unknown as string;
    window.location.href = url;
  };

  const handleLogout = async (grant: Grant) => {
    // setSaving(true);
    await dispatch(actions.auth.logoutOAuth(grant));
    // setSaving(false);
  };

  const handleSkip = () => {
    trackEvent(EVENT_TYPE.ONBOARD_SKIP_CALENDARS);
    onFinish();
  };

  const grants = getGrants(auth);

  // handle return from redirect
  useEffect(() => {
    if (options) {
      const { microsoftCode: code } = options;
      if (code) {
        dispatch(actions.auth.saveOAuthGrant(code, PROVIDER.MICROSOFT, msRedirectURI));
      }
    }
  }, [options, dispatch]);

  return (
    <WelcomeCalendarConnect
      onGoogleSignIn={handleGoogleSignIn}
      googleGrant={grants.find(grant => grant.provider === PROVIDER.GOOGLE.name && grant.signedIn)}
      onMSSignIn={handleMSSignIn}
      msGrant={grants.find(grant => grant.provider === PROVIDER.MICROSOFT.name && grant.signedIn)}
      onLogout={handleLogout}
      onFinish={onFinish}
      handleSkip={handleSkip}
      loading={loading}
      buttonText={buttonText}
      description={description}
    />
  );
};

export default WelcomeCalendarConnectStepContainer;
