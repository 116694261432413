import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_URL } from "../../../constants";
import { actions, RootState, ThunkDispatchType } from "../../../store";
import { COGNITO_ERROR_CODE, INDETERMINATE_AUTH_ERROR_MESSAGE } from "../../../utils/cognitoErrorUtils";
import VerifyEmailStep from "./VerifyEmailStep";
import { useNavigate } from "react-router-dom";
import { resendSignUpCode as amplifyResendSignUpCode } from "aws-amplify/auth";


export interface VerifyEmailStepContainerProps {
  onFinish: () => void;
  email: string;
  password?: string;
}

const VerifyEmailStepContainer = ({
  onFinish, email, password
}: VerifyEmailStepContainerProps): ReactElement => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [showRequestNewCode, setShowRequestNewCode] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    return () => { 
      setLoading(false);
      setErrorMessage([]);
      setShowRequestNewCode(false);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && user?.id) {
      onFinish();
      setLoading(false);
    }
  }, [onFinish, isAuthenticated, user?.id]);

  const handleCodeEntered = async (value: string) => {
    setLoading(true);
    const res = await dispatch(actions.auth.confirmSignup(email, value));
    if (res && res.errorType) {
      setErrorMessage([res.message || res.errorType]);
      setShowRequestNewCode(res.canRequestNewCode || false);
      setLoading(false);
    } else {
      setErrorMessage([]);
      setShowRequestNewCode(false);
      if (email && password) {
        dispatch(actions.auth.login(email, password, true));
      } else {
        navigate(PAGE_URL.LOGIN);
      }
    }
  };

  useEffect(() => {
    if (!email || !password) {
      navigate(PAGE_URL.LOGIN);

      dispatch(actions.globalMessage.sendMessage({
        timeout: 2000,
        message: 'Please login to continue onboarding',
        header: "",
        position: {
          vertical: 'top',
          horizontal: 'center'
        },
        active: true,
        severity: 'info',
        autoDismiss: true,
      }));
    }
  }, [email, password, dispatch, navigate]);

  const handleResendCode = () => {
    if (!toastMessage) {
      setToastMessage('A new code hase been sent, check your inbox.');
      amplifyResendSignUpCode({username: email}).then(() => {
        setLoading(false);
        setErrorMessage([]);
        setShowRequestNewCode(false);
      }).catch((err) => {
        switch (err.code) {
          case COGNITO_ERROR_CODE.CODE_MISMATCH_EXCEPTION: 
            setErrorMessage(['That verification code is not correct. Try again or']);
            setShowRequestNewCode(true);
            break;
          case COGNITO_ERROR_CODE.EXPIRED_CODE_EXCEPTION: 
            setErrorMessage(['This verification link is no longer valid.' + 
          'Check your inbox for a newer link, or']);
            setShowRequestNewCode(true);
            break;
          case COGNITO_ERROR_CODE.USER_NOT_FOUND_EXCEPTION:
            setErrorMessage([INDETERMINATE_AUTH_ERROR_MESSAGE]);
            setShowRequestNewCode(false);
            break;
          case COGNITO_ERROR_CODE.USERNAME_EXISTS_EXCEPTION: 
            setErrorMessage([INDETERMINATE_AUTH_ERROR_MESSAGE]);
            setShowRequestNewCode(false);
            break;
          case COGNITO_ERROR_CODE.LIMIT_EXCEEDED_EXCEPTION: 
            setErrorMessage( ['Please wait a few minutes and try again, then']);
            setShowRequestNewCode(true);
            break;
          default:    
            setErrorMessage(['Something went wrong. Please email help@joincabinet.com for assistance.']);
            break;
        }
      });
    }
  };


  return (
    <VerifyEmailStep 
      email={email} 
      errorMessage={errorMessage} 
      loading={loading} 
      onResendCode={handleResendCode}
      toastMessage={toastMessage}
      onClearToastMessage={() => setToastMessage('')}
      onCodeEntered={handleCodeEntered} 
      offerNewCode={showRequestNewCode}
    />
  );
};

export default VerifyEmailStepContainer;