import { CabButton, CabIcon } from "@CabComponents";
import CabSpinner from "@CabComponents/CabSpinner";
import { Box, Typography } from "@mui/material";
import { IoArrowForward } from 'react-icons/io5';
import { ReactElement, ReactNode } from "react";
import colors from "../../../colors";
import { GOOGLE_CALENDAR_LOGO, GOOGLE_LOGO, MS_CALENDAR_LOGO, MS_LOGO } from "../../../resourceUrls";
import { Grant } from "../../../store";
import StepHeader from "../StepHeader";


export interface WelcomeCalendarConnectStepProps {
  onFinish: () => void;
  handleSkip: () => void;
  onGoogleSignIn: () => void;
  googleGrant?: Grant;
  onMSSignIn: () => void;
  msGrant?: Grant;
  onLogout: (grant: Grant) => void;
  loading: boolean;
  buttonText?: string;
  description: string;
}

const AccountConnectItem = ({ title, text, icon, buttonIcon, grant, onConnect, onLogout }: {
  title: string, text: string, icon: ReactNode, buttonIcon: ReactNode, grant?: Grant,
  onConnect: () => void, onLogout: (grant: Grant) => void
}) => (
  <Box display="flex" flexDirection="row" width="100%" alignItems="center" padding={3}
    flex={1} borderColor={colors.black300}
    sx={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 1, backgroundColor: colors.white900 }}
  >
    <Box display="flex" flexDirection="row" gap={2} width="100%" alignItems="center" overflow={'hidden'}>
      {icon}
      <Box display="flex" flexDirection="column" gap={1} width="100%">
        <Typography fontWeight={700}>{title}</Typography>
        <Box display="flex" flexDirection="row" gap={0.5} alignItems="baseline">
          <Typography variant="body2" whiteSpace="nowrap">
            {grant ? 'Signed in as' : text}
          </Typography>
          {grant && (
            <Typography
              variant="body2"
              fontWeight={700}
              maxWidth={250}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {grant.username}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>

    {grant ? (
      <CabButton
        onClick={() => onLogout(grant)}
        buttonType="tertiary"
        sx={{ height: 46, paddingLeft: 5.5, paddingRight: 5.5, whiteSpace: 'nowrap', marginLeft: 1 }}
      >
        Log Out
      </CabButton>
    ) : (
      <CabButton
        onClick={onConnect}
        buttonType="tertiary"
        color="accent"
        icon={<Box padding={0.5} sx={{ backgroundColor: colors.white900, width: 32 }} paddingBottom={0}>
          {buttonIcon}
        </Box>}
        sx={{ height: 46, color: colors.black900, paddingLeft: 3, paddingRight: 4 }}
      >
        Connect
      </CabButton>
    )}
  </Box>
);


const WelcomeCalendarConnectStep = ({
  onGoogleSignIn, googleGrant, onMSSignIn, msGrant, onLogout, onFinish, handleSkip, loading, buttonText, description
}: WelcomeCalendarConnectStepProps): ReactElement => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <StepHeader
        title="Welcome to Cabinet!"
        text={description}
      />

      <Box display="flex" flexDirection="column" width="100%" flex={1} alignItems="center">
        <Box display="flex" flexDirection="column" gap={3} marginTop={4} width="100%" maxWidth="580px" flex={1}>
          {loading && <CabSpinner />}
          <AccountConnectItem
            title="Google Calendar"
            text="Google and Gmail"
            icon={<img src={GOOGLE_CALENDAR_LOGO} width={50} alt="Google Calendars" />}
            buttonIcon={<img src={GOOGLE_LOGO} height={24} width={24} alt="Google" />}
            grant={googleGrant}
            onConnect={onGoogleSignIn}
            onLogout={onLogout}
          />
          <AccountConnectItem
            title="Microsoft Calendars"
            text="Office 365, Outlook, Live"
            icon={<img src={MS_CALENDAR_LOGO} width={50} alt="Microsoft Calendars" />}
            buttonIcon={<img src={MS_LOGO} height={24} width={24} alt="Microsoft" />}
            grant={msGrant}
            onConnect={onMSSignIn}
            onLogout={onLogout}
          />
        </Box>
      </Box>

      <Box display="flex" width="100%" justifyContent="center" marginTop={6}>
        <CabButton
          size="large"
          endIcon={<CabIcon size="small" Icon={IoArrowForward} />}
          onClick={googleGrant || msGrant ? onFinish : handleSkip }
        >
          {googleGrant || msGrant ? (buttonText || 'Next') : 'Skip'}
        </CabButton>
      </Box>
    </Box>
  );
};

export default WelcomeCalendarConnectStep;
