import { CabButton, CabDropdown, CabIcon } from "@CabComponents";
import { Box, FormControl, Radio, Typography } from "@mui/material";
import { ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";
import colors from "../../../colors";
import { USER_ROLE } from "../../../constants";
import StepHeader from "../StepHeader";
import { ReferenceType } from "../../../store";
import { IoPeopleOutline, IoPersonOutline } from "react-icons/io5";


export interface FormInput {
  userRole: USER_ROLE;
  num_eas: '0' | '1' | '2-5' | '6-10' | '11-30' | '31-100' | '101+' | 'Unknown';
  reference_type: ReferenceType | undefined;
}


export interface UserRoleSelectionProps {
  title?: string;
  individualTitle?: string;
  assistantTitle?: string;
  userRoleOptions: {
    id: USER_ROLE;
    type: USER_ROLE;
    caption: string;
    details: string[];
  }[];
  defaultUserRole?: USER_ROLE;
  defaultNumEas?: '0' | '1' | '2-5' | '6-10' | '11-30' | '31-100' | '101+' | 'Unknown';
  defaultReferenceType?: ReferenceType;
  onFinish: (
    userRole: USER_ROLE, num_eas: '0' | '1' | '2-5' | '6-10' | '11-30' | '31-100' | '101+' | 'Unknown', 
    reference_type: ReferenceType | null ) => void;
  onLogout: () => void;
}

const UserRoleItem = ({ type, selected, onSelect, caption, details, individualTitle, assistantTitle }: {
  type: USER_ROLE, caption: string, details: string[],
  selected: boolean, onSelect: () => void,
  individualTitle?: string; assistantTitle?: string;
}) => (
  <Box display="flex" width="100%" padding={3} height={310} gap={2} onClick={onSelect} justifyContent="space-between"
    flex={1} borderColor={selected ? colors.greenPrimary : colors.black300} maxWidth={765} 
    sx={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 1, backgroundColor: colors.white900, cursor: 'pointer'}}
  >
    <Box display="flex" gap={2} width="100%" alignItems="center" overflow={'hidden'}>
      <Box display="flex" gap={2} width="100%" alignItems="center">
        <CabIcon Icon={type === USER_ROLE.INDIVIDUAL ? IoPersonOutline : IoPeopleOutline} sx={{ fontSize: 60 }} />
        <Box display='flex' flexDirection='column'>
          <Typography variant="body1" fontWeight={600}>
            {{
              individual: individualTitle,
              assistant: assistantTitle,
            }[type]}
          </Typography>
          <Typography variant="body1" fontSize={16} fontWeight={600} color={colors.black800}>
            Good For:
          </Typography>
          <Typography variant="body1" fontSize={16} color={colors.black800}>
            {caption}
          </Typography>
        </Box>
      </Box>
    </Box>
    <Box paddingTop={2}>
      <Radio
        checked={selected}
        onClick={onSelect}
      />
    </Box>
  </Box>
);

const UserRoleSelection = ({
  userRoleOptions, onFinish, defaultUserRole, title = "Tell us about you",
  individualTitle = "For Myself", assistantTitle = "For Someone Else", defaultNumEas, defaultReferenceType, onLogout
}: UserRoleSelectionProps): ReactElement => {
  const { control, formState: { isSubmitting, isValid }, watch, handleSubmit: handleFormSubmit } = useForm<FormInput>({
    values: { userRole: defaultUserRole || USER_ROLE.ASSISTANT, num_eas: defaultNumEas || '0', 
      reference_type: defaultReferenceType },
  });

  const handleFinish = (values: FormInput) => {
    onFinish(values.userRole, values.num_eas, values.reference_type || null);
  };

  const currentUserRole = watch('userRole');

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <StepHeader
        title={title}
        text="Answering a few short questions helps us tailor Cabinet to your needs."
      />

      <Box display="flex" flexDirection="column" flex={1} marginTop={4} alignItems="center" gap={2}>
        <Typography width={'100%'} display='flex' variant="body1" fontWeight={600}>
          For whom are you using Cabinet?
        </Typography>
        <Box display="flex" flexDirection="column" gap={3} width="100%" flex={1} justifyContent="center">
          <Controller name="userRole" control={control}
            render={({ field: { ref, value, onChange, ...field } }) => (
              <>
                {userRoleOptions.map(userRole => (
                  <UserRoleItem
                    individualTitle={individualTitle}
                    assistantTitle={assistantTitle}
                    key={userRole.id}
                    type={userRole.type}
                    caption={userRole.caption}
                    details={userRole.details}
                    selected={value === userRole.id}
                    onSelect={() => onChange(userRole.id)}
                  />
                ))}
              </>
            )}
          />
          {currentUserRole === USER_ROLE.ASSISTANT && (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Typography width={'100%'} display='flex' variant="body1" fontWeight={600}>
                How many EAs and Admins work at your company?
              </Typography>
              <FormControl sx={{ flex: 1, width: '100%' }}>
                <Controller name="num_eas" control={control} rules={{ required: true }} 
                  render={({ field: { ref, ...field }, fieldState: {error} }) => {
                    return <><CabDropdown {...field} options={[
                      { value: '0', label: 'None' },
                      { value: '1', label: 'Just Me' },
                      { value: '2-5', label: '2-5' },
                      { value: '6-10', label: '6-10' },
                      { value: '11-30', label: '11-30' },
                      { value: '31-100', label: '31-100' },
                      { value: '101+', label: '101+' },
                    ]} 
                    sx={{
                      '&.MuiOutlinedInput-root':{
                        backgroundColor: colors.white900
                      },
                    }}
                    />
                    </>;
                  }}
                />
              </FormControl>
            </Box>
          )}
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Typography width={'100%'} display='flex' variant="body1" fontWeight={600}>
              How did you hear about Cabinet?
            </Typography>
            <FormControl sx={{ flex: 1, width: '100%' }}>
              <Controller name="reference_type" control={control} rules={{ required: true }} 
                render={({ field: { ref, ...field }, fieldState: {error} }) => {
                  return <><CabDropdown {...field} options={[
                    { value: ReferenceType.BLOG_POST, label: "Blog post" },
                    { value: ReferenceType.GOOGLE_SEARCH, label: "Google search" },
                    { value: ReferenceType.COMPANY_USER, label: "My company uses Cabinet" },
                    { value: ReferenceType.WORD_OF_MOUTH, label: "Word of mouth" },
                    { value: ReferenceType.SCHEDULING_LINK, label: "Scheduling link" },
                    { value: ReferenceType.EVENT, label: "Event" },
                    { value: ReferenceType.CABINET_NEWSLETTER, label: "Cabinet newsletter" },
                    { value: ReferenceType.EMAIL_FROM_CABINET, label: "Email from Cabinet" },
                    { value: ReferenceType.LINKEDIN_MESSAGE_FROM_CABINET, label: "LinkedIn message from Cabinet" },
                    { value: ReferenceType.OTHER, label: "Other" },
                  ]} 
                  sx={{
                    '&.MuiOutlinedInput-root':{
                      backgroundColor: colors.white900
                    },
                  }}
                  />
                  </>;
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box display="flex" width="100%" justifyContent="center" marginTop={6}>
        <Box display="flex" flexDirection="column" gap={4}>
          <CabButton
            size="large"
            onClick={() => handleFormSubmit(handleFinish)()}
            disabled={isSubmitting || !isValid}
            sx={{ width: 190 }}
          >
            Continue
          </CabButton>  
          <CabButton
            size="large"
            buttonType="text"
            onClick={onLogout}
            disabled={isSubmitting}
            sx={{ width: 190, textDecoration: 'underline' }}
          >
            Logout
          </CabButton>  
        </Box>
      </Box>
    </Box>
  );
};

export default UserRoleSelection;
