import { ReactElement, useCallback } from "react";
import UserType, { UserRoleSelectionProps } from "./UserType";
import { actions, ThunkDispatchType, RootState, ReferenceType } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_URL, USER_ROLE } from "../../../constants";
import { useNavigate } from "react-router";


export interface UserTypeContainerProps {
  onFinish: () => void;
}

const userRoleOptions: UserRoleSelectionProps['userRoleOptions'] = [
  {
    id: USER_ROLE.INDIVIDUAL,
    type: USER_ROLE.INDIVIDUAL,
    caption: 'Individuals who want to use Cabinet to manage their own schedules',
    details: [],
  },
  {
    id: USER_ROLE.ASSISTANT,
    type: USER_ROLE.ASSISTANT,
    caption: "Assistants who want to manage one or more executives",
    details: [],
  },
];

const UserTypeContainer = ({ onFinish }: UserTypeContainerProps): ReactElement => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector((state: RootState) => state.organization);
  const logout = useCallback(async () => {
    await dispatch(actions.auth.logout());
    navigate(PAGE_URL.LOGIN);
  }, [dispatch, navigate]);

  const handleFinish = (
    userRole: USER_ROLE, num_eas: '0' | '1' | '2-5' | '6-10' | '11-30' | '31-100' | '101+' | 'Unknown', 
    reference_type: ReferenceType | null) => {
    if (user?.active_license) {
      dispatch(actions.organization.updateOrganizationLicense({
        id: user.active_license.id,
        user_role: userRole === USER_ROLE.ASSISTANT ? USER_ROLE.ASSISTANT : USER_ROLE.INDIVIDUAL,
        tier: user.active_license.tier,
      }));
      dispatch(actions.organization.updateOrganization({
        num_eas: num_eas,
        id: organization.id
      }));
      dispatch(actions.auth.setProfileReferenceType(reference_type));
    }
    onFinish();
  };

  return (
    <UserType
      userRoleOptions={userRoleOptions}
      defaultUserRole={user?.active_license.user_role}
      defaultNumEas={organization?.num_eas || '0'}
      defaultReferenceType={user?.profile?.reference_type || undefined}
      onFinish={handleFinish}
      onLogout={logout}
    />
  );
};

export default UserTypeContainer;
