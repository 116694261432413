import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { IoArrowForward } from 'react-icons/io5';
import { CabButton, CabIcon } from "@CabComponents";
import StepHeader from "../StepHeader";
import ZoomSignIn from "../../Integrations/ZoomSignIn";
import { Grant, PresetLocations } from "../../../store";
import { PAGE_URL } from "../../../constants";
import { Control, useForm } from "react-hook-form";
import LocationPresetWidget from "../../Schedule/LocationPresetWidget";
import { ZOOM_SIGN_IN_BUTTON } from "../../../resourceUrls";
import colors from "../../../colors";


export type PresetLocationFormInput = {
  locations: {
    id?: number;
    name: string;
    location: string;
  }[];
};

export interface VirtualConferenceConnectProps {
  onFinish: () => Promise<void>;
  showZoom?: boolean;
  zoomGrant?: Grant;
  hasGoogleConnected?: boolean;
  hasMSConnected?: boolean;
  buttonText?: string;
  onZoomSignOut?: () => void;
  presetLocations: PresetLocations;
  onPresetLocationSubmit: (locations: PresetLocationFormInput) => Promise<void>;
}

export const zoomRedirectURI = `${PAGE_URL.SIGNUP}/oauth/zoom`;

const VirtualConferenceConnect = ({
  onFinish, zoomGrant, buttonText, onZoomSignOut: handleZoomSignOut, hasGoogleConnected, hasMSConnected, showZoom,
  presetLocations, onPresetLocationSubmit,
}: VirtualConferenceConnectProps): ReactElement => {
  const integrationText = hasGoogleConnected && hasMSConnected ? 'Google Meet and Microsoft Teams'
    : hasGoogleConnected ? 'Google Meet' : hasMSConnected ? 'Microsoft Teams' : null;
  const text = `${integrationText ? 'In addition to ' + integrationText + ', you' : 'You'}
    can configure Cabinet's meeting invitations to include ${showZoom ? 'Zoom, ' : ''}phone numbers,
    addresses, or even URLs for other conferencing solutions.`;

  const locations = Object.values(presetLocations).length
    ? Object.values(presetLocations)
    : [{ location: '', name: '' }];

  const {
    control, getValues
  } = useForm<PresetLocationFormInput>({
    mode: 'onBlur',
    values: { locations },
    defaultValues: { locations },
  });


  const handleSignInDone = (providerId: number) => {
    return;
  };

  const handleSubmit = async () => {
    handlePresetLocationSubmit(getValues());
    await onFinish();
  };

  const handlePresetLocationSubmit = async (data: PresetLocationFormInput) => {
    await onPresetLocationSubmit(data); 
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={3} paddingBottom={4}>
      <StepHeader
        title='Meeting Location Options'
        text={text}
      />
      {showZoom && (
        <Box maxWidth={765} width={'100%'} marginBottom={2} marginTop={1}>
          {zoomGrant && (
            <Box display='flex' flexDirection='column' gap={1}>
              <Box display='flex' flexDirection='column'>
                <Typography variant='h4' fontWeight={500}>Connect Zoom</Typography>
                <Typography variant='body1' color={colors.black700}>
                  This can be updated at any time on the integrations settings page.
                </Typography>
              </Box>
              <Typography variant="body1">
                <b>Connected to:</b> {zoomGrant.username}
              </Typography>
              {handleZoomSignOut && (
                <CabButton
                  onClick={() => handleZoomSignOut()}
                  buttonType="tertiary"
                  color="accent"
                  endIcon={ <img src={ZOOM_SIGN_IN_BUTTON} height={18} style={{marginLeft: -8,}} alt="Zoom" />}
                  sx={{ 
                    height: 48, color: colors.black900, maxWidth: 400,
                    border: 1.5, borderColor: colors.black200,
                    '& .MuiButton-endIcon': {
                      mixBlendMode: 'multiply'
                    }
                  }}
                >
                  Log Out
                </CabButton>
              )}
            </Box>
          )}
          <ZoomSignIn
            onOAuthGranted={handleSignInDone}
            grant={zoomGrant}
            zoomRedirectURI={zoomRedirectURI}
            isOnboarding
            useVerticalLayout
          />
        </Box>
      )}

      <Box sx={{ width: '100%', maxWidth: 765 }}>
        {showZoom && (
          <>
            <Typography variant="h2" fontSize={18} marginBottom={3}>Additional Meeting Locations:</Typography>
            <Typography variant="body1" marginBottom={3}>
              Add frequently used locations, phone numbers, or conference URLs
            </Typography>
          </>
        )}
        <LocationPresetWidget name="locations" control={control as unknown as Control} />
        <Box display="flex" width='100%' justifyContent="center" marginTop={6}>
          <CabButton
            size="large"
            onClick={handleSubmit}
            endIcon={<CabIcon size="small" Icon={IoArrowForward} />}
          >
            {buttonText || "Next"}
          </CabButton>
        </Box>
      </Box>
    </Box>
  );
};

export default VirtualConferenceConnect;
